import React, { useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FaRegTimesCircle as RemoveIcon } from 'react-icons/fa';

import SelectInput from '../../../../newComponents/SelectInput';

const Weather = ({
  index,
  group,
  groupOptionList,
  handleChangeWeatherOption,
  handleChangeWeatherOptionRemove,
  formValidate,
}) => {
  const { t } = useTranslation();

  const defaultDevice = useRef([
    { title: t('manager.editor.layerArea.component.weather.group.default'), value: 'DEFAULT' },
  ]);

  const deviceLocationList = useRef([
    {
      value: '',
      title: t('manager.editor.layerArea.component.weather.location.all'),
    },
    {
      value: '서울특별시',
      title: '서울특별시',
    },
    {
      value: '경기도',
      title: '경기도',
    },
    {
      value: '인천광역시',
      title: '인천광역시',
    },
    {
      value: '강원도',
      title: '강원도',
    },
    {
      value: '광주광역시',
      title: '광주광역시',
    },
    {
      value: '전라남도',
      title: '전라남도',
    },
    {
      value: '전라북도',
      title: '전라북도',
    },
    {
      value: '세종특별자치시',
      title: '세종특별자치시',
    },
    {
      value: '대전광역시',
      title: '대전광역시',
    },
    {
      value: '충청남도',
      title: '충청남도',
    },
    {
      value: '충청북도',
      title: '충청북도',
    },
    {
      value: '부산광역시',
      title: '부산광역시',
    },
    {
      value: '울산광역시',
      title: '울산광역시',
    },
    {
      value: '대구광역시',
      title: '대구광역시',
    },
    {
      value: '경상남도',
      title: '경상남도',
    },
    {
      value: '경상북도',
      title: '경상북도',
    },
    {
      value: '제주특별자치도',
      title: '제주특별자치도',
    },
  ]);

  return (
    <Container>
      <Box>
        <LeftArea>{t('manager.editor.layerArea.component.weather.group.title')}</LeftArea>
        <RightArea>
          {index === 0 ? (
            <SelectInput selectedValue="DEFAULT" optionList={defaultDevice.current} disable={true} />
          ) : (
            <SelectInput
              selectedValue={group?.targetGroupId}
              optionList={groupOptionList}
              onSelectChange={value => handleChangeWeatherOption(index, 'targetGroupId', value)}
              defaultTitle={group?.targetGroupId}
              isValidate={formValidate.locationList[index] && formValidate.locationList[index].targetGroupId}
            />
          )}
          <InputError>
            {formValidate.locationList[index] && formValidate.locationList[index].targetGroupIdError}
          </InputError>
        </RightArea>
      </Box>
      <Box>
        <LeftArea>{t('manager.editor.layerArea.component.weather.location.title')}</LeftArea>
        <RightArea>
          <SelectInput
            selectedValue={group?.location}
            optionList={deviceLocationList.current}
            onSelectChange={value => handleChangeWeatherOption(index, 'location', value)}
            isValidate={formValidate.locationList[index] && formValidate.locationList[index].location}
          />
          <InputError>{formValidate.locationList[index] && formValidate.locationList[index].locationError}</InputError>
        </RightArea>
      </Box>
      {index > 0 && (
        <ButtonWrapper>
          <StyledRoundBtn onClick={() => handleChangeWeatherOptionRemove(index)}>
            <RemoveIcon color="#666666" size={25} />
          </StyledRoundBtn>
        </ButtonWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 1rem;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
`;

const LeftArea = styled.div`
  flex: 1;
  align-items: center;
  font-size: 14px;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
`;

const InputError = styled.label`
  height: 1.25rem;
  padding: 0.125rem 0.25rem;
  color: #f05b5b;
  font-size: 12px;
  line-height: normal;
  font-weight: 500;
`;

const RightArea = styled.div`
  flex: 3;
  align-items: center;

  display: flex;
  justify-content: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledRoundBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 17px;
  background: #ffffff;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #ececec;
  }
`;

export default React.memo(Weather);
